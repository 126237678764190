
import Vue, { PropType } from 'vue'
import { HymnResultsApi } from '~/apiclient/apihymns'
import HymnalAlphaHymnList from '~/components/hymnal/AlphaHymnList.vue'

export default Vue.extend({
  name: 'HymnalCategoryElement',
  components: { HymnalAlphaHymnList },
  props: {
    list: {
      type: Object as PropType<Record<string, HymnResultsApi[]>>,
      required: true,
    },
  },
  computed: {
    filteredList(): Record<string, HymnResultsApi[]> {
      const list = { ...this.list }
      Object.keys(list).forEach((letter) => {
        if (!list[letter].length) delete list[letter]
      })
      return list
    },
  },
})
