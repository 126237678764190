
import Vue from 'vue'
import { mapGetters } from 'vuex'
import HymnalSearchLayout from '~/components/hymnal/SearchLayout.vue'
import HymnalCategoryElement from '~/components/hymnal/Category.vue'
import { HymnOptions, HymnResultsApi, TuneApi } from '~/apiclient/apihymns'
import { Alphabet } from '~/assets/ts/utils/misc'
import { getListWithPlaceholders } from '~/assets/ts/utils/lists'
import SiteLayoutWithPromo from '~/components/site/layout/WithPromo.vue'
import SitePsalterPromo from '~/components/site/psalter/Promo.vue'
import ScrollList from '~/components/_general/ScrollList.vue'
import HymnElement from '~/components/_general/HymnElement.vue'
import HymnalPsalterTuneList from '~/components/hymnal/PsalterTuneList.vue'
import NavigationTabList from '~/components/_general/NavigationTabList.vue'
import { NavigationTabItem, NavigationTabs } from '~/assets/ts/enums'

export interface TuneListItem {
  tune: TuneApi
  open: boolean
  psalms: HymnResultsApi[] | undefined[]
  hasPsalms: boolean
}

export default Vue.extend({
  name: 'HymnalTabbed',
  components: {
    NavigationTabList,
    HymnalPsalterTuneList,
    HymnElement,
    ScrollList,
    SitePsalterPromo,
    SiteLayoutWithPromo,
    HymnalCategoryElement,
    HymnalSearchLayout,
  },
  props: {
    tabQuery: {
      type: String,
      default: 'popular',
    },
  },
  async fetch() {
    await this.getLists()
  },
  computed: {
    ...mapGetters({
      alphaHymns: 'hymns/alphaHymnList',
      psalterHymns: 'hymns/psalterHymnList',
      popularHymns: 'hymns/popHymnList',
    }),
    tabs(): NavigationTabs {
      return [this.popularTab, this.alphaTab, this.psalterTab, this.tuneTab]
    },
    alphabet(): string[] {
      return Alphabet.toUpperCase().split('')
    },
    popularTab(): NavigationTabItem {
      return {
        title: this.$t('Popular'),
        slug: 'popular',
        url: '/hymnal?tab=popular',
      }
    },
    alphaTab(): NavigationTabItem {
      return {
        title: this.$t('Alphabetical'),
        slug: 'alphabetical',
        url: '/hymnal?tab=alphabetical',
      }
    },
    psalterTab(): NavigationTabItem {
      return {
        title: this.$t('Psalter'),
        slug: 'psalter',
        url: '/psalter/',
      }
    },
    tuneTab(): NavigationTabItem {
      return {
        title: this.$t('Psalter Tunes'),
        slug: 'tunes',
        url: '/psalter?tab=tunes',
      }
    },
    isAlpha(): boolean {
      return this.tabQuery === this.alphaTab.slug
    },
    isPsalter(): boolean {
      return (
        this.tabQuery === this.psalterTab.slug ||
        this.tabQuery === this.tuneTab.slug
      )
    },
    isTune(): boolean {
      return this.tabQuery === this.tuneTab.slug
    },
    isList(): boolean {
      return !this.isAlpha && !this.isTune
    },
    nonAlphaList(): HymnResultsApi[] {
      return getListWithPlaceholders(
        this.isPsalter ? this.psalterHymns : this.popularHymns
      )
    },
    endOfList(): boolean {
      return this.isPsalter
        ? this.$store.getters['hymns/maxPsalterReached']
        : this.$store.getters['hymns/maxPopReached']
    },
    // puts alphabetical list in format to be used by HymnalCategory component
    alphaList(): Record<string, HymnResultsApi[]> {
      const list: Record<string, HymnResultsApi[]> = {}
      this.alphabet.forEach((letter) => {
        list[letter] = []
      })

      this.alphaHymns.forEach((hymn: HymnResultsApi) => {
        // Get first letter of hymn title - thanks to SO!
        const letter = (
          (hymn.title.match(/[a-zA-Z]/) || []).pop() || ''
        ).toUpperCase()

        list[letter].push(hymn)
      })
      return list
    },
  },
  watch: {
    '$route.query.tab'() {
      this.getLists()
    },
  },
  methods: {
    async getMoreHymns() {
      await this.$store.dispatch(
        !this.isPsalter
          ? 'hymns/fetchPopularHymnList'
          : 'hymns/fetchPsalterList',
        {
          pageSize: this.isPsalter ? undefined : 100,
        } as HymnOptions
      )
    },
    async getLists() {
      if (this.isAlpha) {
        await this.$store.dispatch('hymns/fetchAlphaHymnList')
      } else {
        await this.getMoreHymns()
      }
    },
  },
})
