
import Vue, { PropType } from 'vue'
import { HymnResultsApi } from '~/apiclient/apihymns'
import ExplicitHeightElement from '~/components/_general/ExplicitHeightElement.vue'
import HymnElement from '~/components/_general/HymnElement.vue'
import InlineIcon from '~/components/_general/InlineIcon.vue'

export default Vue.extend({
  name: 'HymnalAlphaHymnList',
  components: { InlineIcon, HymnElement, ExplicitHeightElement },
  props: {
    letter: {
      type: String,
      required: true,
    },
    list: {
      type: Array as PropType<HymnResultsApi[]>,
      required: true,
    },
  },
  data() {
    return {
      mounted: false,
      open: false,
      previewCount: 2,
      hymnClasses: 'border-b border-theme mt-5 pb-5',
    }
  },
  computed: {
    shortList(): HymnResultsApi[] {
      return this.list.slice(0, this.previewCount)
    },
    remainingList(): HymnResultsApi[] {
      return this.list.slice(this.previewCount, this.list.length)
    },
    hasRemaining(): boolean {
      return !!this.remainingList.length
    },
  },
  mounted() {
    this.mounted = true
  },
})
